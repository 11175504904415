import React from 'react';

const IconLogo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="800.000000pt"
    height="800.000000pt"
    viewBox="0 0 800.000000 800.000000"
    preserveAspectRatio="xMidYMid meet">
    <g
      transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none">
      <path
        d="M3249 7262 c-405 -235 -1122 -650 -1593 -922 l-856 -494 0 -1846 0
-1846 878 -506 c482 -278 1201 -693 1597 -922 413 -239 726 -414 734 -411 16
6 584 334 2194 1263 l997 577 0 1845 0 1845 -1037 600 c-571 329 -1289 744
-1596 922 -307 178 -563 323 -570 322 -7 0 -343 -192 -748 -427z m1329 -154
c306 -177 971 -561 1477 -853 l920 -531 0 -1723 0 -1722 -925 -536 c-509 -294
-1178 -681 -1488 -860 l-562 -325 -718 415 c-394 228 -1063 615 -1487 860
l-770 445 0 1723 0 1723 730 422 c402 231 1070 617 1485 857 415 240 761 435
768 432 6 -2 263 -149 570 -327z"
      />
      <path
        d="M4575 5611 c-81 -14 -121 -55 -168 -171 -527 -1309 -1177 -2929
-1177 -2934 0 -3 42 -6 93 -6 137 1 186 28 240 135 24 46 1197 2968 1197 2980
0 7 -136 4 -185 -4z"
      />
      <path
        d="M4990 4766 c0 -143 11 -183 60 -217 44 -30 943 -483 995 -502 27 -9
57 -20 65 -23 9 -4 -10 -15 -50 -28 -36 -11 -282 -130 -547 -264 -357 -179
-487 -250 -500 -269 -24 -36 -34 -319 -11 -311 7 3 355 184 773 401 l760 395
0 73 0 73 -772 402 -773 401 0 -131z"
      />
      <path
        d="M2235 4491 l-770 -397 0 -73 0 -72 550 -285 c303 -157 650 -337 773
-400 l222 -115 0 146 c0 144 0 145 -27 175 -41 45 -953 505 -1044 527 -38 9
-69 20 -69 23 0 3 29 14 65 23 86 23 1033 505 1058 539 15 20 17 42 15 165
l-3 141 -770 -397z"
      />
    </g>
  </svg>
);

export default IconLogo;
